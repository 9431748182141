import React from 'react'
import JsWaves from 'components/JsWaves'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  return (
    <Inview className={`${styles.component} fade-in up`}>
      <JsWaves shortView={true} />
    </Inview>
  )
}

export default Block
