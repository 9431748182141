import React from 'react'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import * as styles from './styles.module.scss'

const Block = ({ content, theme }) => {
  const { title, body, buttonLink } = content
  return (
    <div className='block pad-v-standard'>
      <Inview className='container fade-in up grid-12'>
        <div className={styles.textWrap}>
          {title && <h2>{title}</h2>}
          {body && <MarkdownWrap className={styles.body} body={body} />}
          {buttonLink && (
            <div className='button-wrap'>
              <ButtonLink content={buttonLink} theme={theme} />
            </div>
          )}
        </div>
      </Inview>
    </div>
  )
}

export default Block
