import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ content, locale }) => {
  const { previousPagePath, nextPagePath, pageNumbers } = content

  const strings = {
    en: {
      prev: 'Previous',
      next: 'Next',
    },
    zh: {
      prev: '上一个',
      next: '下一个',
    },
  }
  return (
    <Inview className={`${styles.component} fade-in up`}>
      <div className={styles.wrap}>
        <div className={styles.side}>
          {previousPagePath && (
            <LinkWrap to={previousPagePath}>
              <h6>{strings[locale].prev}</h6>
            </LinkWrap>
          )}
        </div>
        <div className={styles.mid}>
          {pageNumbers.map((item, i) => (
            <LinkWrap
              to={item.to}
              key={i}
              className={!item.to ? styles.active : ''}
            >
              <h6>{item.number}</h6>
            </LinkWrap>
          ))}
        </div>
        <div className={styles.side}>
          {nextPagePath && (
            <LinkWrap to={nextPagePath}>
              <h6>{strings[locale].next}</h6>
            </LinkWrap>
          )}
        </div>
      </div>
    </Inview>
  )
}

export default Component
