import React from 'react'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import * as styles from './styles.module.scss'

const Block = ({ content, theme }) => {
  const { title, body, buttonLink, isDark = false } = content
  const bgDarkClass = isDark ? 'bg-dark no-collapse ' : ''
  return (
    <div className={`block pad-v-standard ${bgDarkClass}`}>
      <Inview className={`${styles.container} container fade-in up`}>
        <div className={styles.text}>
          {title && <h2>{title}</h2>}
          {body && <MarkdownWrap body={body} />}
          {buttonLink && (
            <div className='button-wrap centered fade-in up'>
              <ButtonLink content={buttonLink} theme={theme} />
            </div>
          )}
        </div>
      </Inview>
    </div>
  )
}

export default Block
