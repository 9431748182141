import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import TablePosts from 'components/TablePosts'
import * as styles from './styles.module.scss'

const Block = ({ data, locale, theme }) => {
  const investorPosts = data?.investorPosts?.nodes
  const investorPostsLocale = investorPosts?.map(
    (item) => item.frontmatter[locale]
  )

  const tablePosts = {
    items: investorPostsLocale.map((item) => {
      return {
        date: item.date,
        title: item.title,
        link: `/investors/${item.slug}`,
        file: item.file,
      }
    }),
  }

  return (
    <div className='block pad-v-standard'>
      <div className='container grid-24'>
        <div className={styles.tableWrap}>
          <TablePosts content={tablePosts} locale={locale} theme={theme} />
        </div>
      </div>
    </div>
  )
}

export default function Data({ content, locale, theme }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          investorPosts: allMarkdownRemark(
            filter: {
              frontmatter: {
                en: {
                  templateKey: { eq: "investorPost" }
                  slug: { ne: "placeholder" }
                }
              }
            }
            sort: { fields: frontmatter___en___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                en {
                  date
                  title
                  slug
                  file {
                    publicURL
                  }
                }
                zh {
                  date
                  title
                  slug
                  file {
                    publicURL
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Block content={content} data={data} locale={locale} theme={theme} />
      )}
    />
  )
}
