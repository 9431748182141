import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/Seo'
import PageTemplate from './template'

const ContentType = (props) => {
  const { data, pageContext, layoutProps } = props
  const { locale } = pageContext
  const { page } = data
  const content = page.frontmatter[locale]
  return (
    <>
      <Seo content={content} locale={locale} />
      <PageTemplate
        pageId={page.id}
        content={content}
        locale={locale}
        layoutProps={layoutProps}
        pageContext={pageContext}
      />
    </>
  )
}

export default ContentType

export const pageQuery = graphql`
  query ($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        en {
          title
          blocks {
            type
            body
            buttonLink {
              text
              to
              linkType
            }
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            imageSet {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              imageMobile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            isImageFirst
            items {
              title
              subtitle
              body
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            newsPost
            pretitle
            table
            title
            title2
            subtitle
          }
          seo {
            title
            description
          }
        }
        zh {
          title
          blocks {
            type
            body
            buttonLink {
              text
              to
              linkType
            }
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            imageSet {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              imageMobile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            isImageFirst
            items {
              title
              subtitle
              body
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            newsPost
            pretitle
            table
            title
            title2
            subtitle
          }
          seo {
            title
            description
          }
        }
      }
    }
  }
`
