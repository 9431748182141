import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ImageFullTextFloating from 'blocks/ImageFullTextFloating/template'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const { title } = content

  const locations = data?.locations?.nodes
  const locationsLocale = locations?.map((item) => item.frontmatter[locale])

  const buttonText = {
    en: 'Learn More',
    zh: '了解更多',
  }

  const blocks = locationsLocale.map((item, i) => {
    return {
      pretitle: item.description,
      title: item.title,
      imageSet: item.imageSet,
      buttonLink: {
        to: `/locations/${item.slug}`,
        text: buttonText[locale],
        linkType: 'page',
      },
      isBoxLeft: i % 2 === 1,
    }
  })

  return blocks?.map((item, i) => (
    <ImageFullTextFloating content={item} key={i} />
  ))
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          locations: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "location" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  description
                  imageSet {
                    image {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    imageMobile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                zh {
                  slug
                  title
                  description
                  imageSet {
                    image {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    imageMobile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
