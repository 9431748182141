import React from 'react'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { title, body, buttonLink } = content
  return (
    <div className='block pad-v-standard'>
      <Inview className='container fade-in up grid-24'>
        <div className={styles.title}>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className={styles.body}>
          <MarkdownWrap className='p-smaller' body={body} />
          {buttonLink && (
            <div className='button-wrap'>
              <ButtonLink content={buttonLink} />
            </div>
          )}
        </div>
      </Inview>
    </div>
  )
}

export default Block
