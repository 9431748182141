import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import LeftTextSlideshow from 'components/Slideshows/LeftTextSlideshow'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const { title, buttonLink } = content

  const productCats = data?.productCats?.nodes
  const productCatsLocale = productCats?.map((item) => item.frontmatter[locale])

  const slideshow = {
    items: productCatsLocale,
  }

  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        <Inview className='fade-in up grid-12'>
          <div className={styles.title}>
            <h2>{title}</h2>
          </div>
        </Inview>
        <div className={styles.slideshowWrap}>
          <LeftTextSlideshow content={slideshow} locale={locale} />
        </div>
        {buttonLink && (
          <Inview className='fade-in up grid-12'>
            <div className={styles.buttonLink}>
              <ButtonLink content={buttonLink} />
            </div>
          </Inview>
        )}
      </div>
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          productCats: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "productCat" } } }
            }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  title
                  subtitle
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  title
                  subtitle
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
