import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import * as styles from './styles.module.scss'

const Block = ({ pageId, content }) => {
  const { pretitle, title, body, image, buttonLink } = content
  let pageClass = ''
  if (pageId === '4d2b2f7c-374e-5a73-8efe-f5c67b0e0700') {
    pageClass = styles.map
  }
  return (
    <div className={`${pageClass} block pad-v-standard`}>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          {pretitle && <h5>{pretitle}</h5>}
          <h2>{title}</h2>
          {body && (
            <div className={`body centered fade-in up`}>
              <MarkdownWrap body={body} />
            </div>
          )}
        </Inview>
        <Inview className={`grid-24 ${styles.image} fade-in up`}>
          <ImageWrap image={image} />
        </Inview>
        {buttonLink && (
          <Inview className={`button-wrap centered fade-in up`}>
            <ButtonLink content={buttonLink} />
          </Inview>
        )}
      </div>
    </div>
  )
}

export default Block
