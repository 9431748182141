import React, { useState, useEffect } from 'react'
// import ParallaxWrapBanner from 'components/ParallaxWrapBanner'
import JsWaves from 'components/JsWaves'
import * as styles from './styles.module.scss'

const Block = ({ content, locale, layoutProps }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
    // window.addEventListener('resize', getVideoUrl)
    // return () => window.removeEventListener('resize', getVideoUrl)
  }, [])

  const scrollDown = (e) => {
    const block = e.target.closest('.block')
    window.scrollTo({
      top: block.offsetHeight,
      behavior: 'smooth',
    })
  }

  const { title, title2 } = content
  const isLoadedClass = isLoaded ? styles.loaded : ''
  return (
    <div className={`block ${styles.component} ${isLoadedClass} bg-dark`}>
      <div className={styles.animation}>
        <JsWaves />
      </div>
      <div className={styles.text}>
        <div className={styles.wrap}>
          <div className='container'>
            <h1>{title}</h1>
            <h1>{title2}</h1>
          </div>
        </div>
      </div>
      <div className={styles.scrollDown} onClick={scrollDown}>
        <div className={styles.arrow} />
      </div>
    </div>
  )
}

export default Block
