import React from 'react'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
import PdfSvg from 'assets/svg/icons/pdf.svg'

const Block = ({ content, theme }) => {
  const { title, post } = content
  const { pretitle, excerpt, buttonLink, file } = post || {}
  const themeClass = theme === 'dark' ? styles.themeDark : ''
  return (
    <div className={`block pad-v-standard first-add-v-pad ${themeClass}`}>
      <Inview className='container fade-in up'>
        <div className={styles.title}>
          <h2>{title}</h2>
        </div>
        {post && (
          <div className={styles.post}>
            <h6 dangerouslySetInnerHTML={{ __html: pretitle }} />
            <MarkdownWrap body={excerpt} />
            <div className={styles.linkWrap}>
              {file && (
                <a href={file.publicURL} target='_blank'>
                  <PdfSvg />
                </a>
              )}
              {!file && <ButtonLink content={buttonLink} theme={theme} />}
            </div>
          </div>
        )}
      </Inview>
    </div>
  )
}

export default Block
