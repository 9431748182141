import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import BannerPost from 'blocks/BannerPost/template'
import { formatDate } from 'js/utils'

const Block = ({ content, data, locale, theme }) => {
  const { title } = content

  const featuredPosts = data?.featuredPosts?.nodes
  const featuredPostsLocale = featuredPosts?.map(
    (item) => item.frontmatter[locale]
  )
  const thisPost = featuredPostsLocale[0]

  let bannerPost = {
    title,
  }

  const more = {
    en: 'More',
    zh: '更多',
  }

  if (thisPost) {
    const dateFormatted = formatDate(thisPost.date, locale)
    bannerPost = {
      title,
      post: {
        pretitle: dateFormatted,
        excerpt: thisPost.excerpt,
        buttonLink: {
          to: `/news/${thisPost.slug}`,
          text: more[locale],
          linkType: 'page',
        },
        file: thisPost.file,
      },
    }
  }

  return <BannerPost content={bannerPost} theme={theme} />
}

export default function Data({ content, locale, theme }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          featuredPosts: allMarkdownRemark(
            filter: {
              frontmatter: {
                en: {
                  templateKey: { eq: "newsPost" }
                  isFeatured: { eq: true }
                }
              }
            }
            sort: { fields: frontmatter___en___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                en {
                  date
                  location
                  title
                  slug
                  excerpt
                }
                zh {
                  date
                  location
                  title
                  slug
                  excerpt
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Block content={content} data={data} locale={locale} theme={theme} />
      )}
    />
  )
}
