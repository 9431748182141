import React from 'react'
import MarkdownWrap from 'components/MarkdownWrap'
import ParallaxWrap from 'components/ParallaxWrap'
import ImageSet from 'components/ImageSet'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { title, body, imageSet } = content
  return (
    <div className={`block ${styles.component}`}>
      <Inview className={`abs-fill fade-in ${styles.image}`}>
        <ParallaxWrap>
          <ImageSet content={imageSet} />
        </ParallaxWrap>
      </Inview>
      <Inview className={`_abs-fill fade-in up pad-v-standard text-center`}>
        <div className='container'>
          <div className={styles.wrap}>
            {title && <h2>{title}</h2>}
            {body && <MarkdownWrap body={body} />}
          </div>
        </div>
      </Inview>
    </div>
  )
}

export default Block
