import React from 'react'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { pretitle, title, subtitle, body } = content
  return (
    <div className={`block pad-v-standard first-add-v-pad `}>
      <Inview className='container fade-in up'>
        <div className={styles.text}>
          {pretitle && <h5>{pretitle}</h5>}
          <h2>{title}</h2>
          {subtitle && <h5>{subtitle}</h5>}
          {body && (
            <div className={`body ${styles.body}`}>
              <MarkdownWrap body={body} />
            </div>
          )}
        </div>
      </Inview>
    </div>
  )
}

export default Block
