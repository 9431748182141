import * as THREE from 'three'
import React, { useEffect, useRef } from 'react'
import * as styles from './styles.module.scss'

const Component = ({ shortView = false }) => {
  const mountRef = useRef(null)

  useEffect(() => {
    const ShapeUrl = '/assets/diamond-solid.png'

    const SEPARATION = 100,
      AMOUNTX = 70,
      AMOUNTY = 70

    let container
    let camera, scene, renderer

    let particles,
      count = 0

    let mouseX = 0,
      mouseY = -260

    let windowHalfX = window.innerWidth / 2
    let windowHalfY = window.innerHeight / 2

    function init() {
      camera = new THREE.PerspectiveCamera(
        800,
        window.innerWidth / window.innerHeight,
        shortView ? 1000 : 1,
        10000
      )
      camera.position.z = 1000

      scene = new THREE.Scene()

      //

      const numParticles = AMOUNTX * AMOUNTY

      const positions = new Float32Array(numParticles * 3)
      const scales = new Float32Array(numParticles)

      let i = 0,
        j = 0

      for (let ix = 0; ix < AMOUNTX; ix++) {
        for (let iy = 0; iy < AMOUNTY; iy++) {
          positions[i] = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2 // x
          positions[i + 1] = 0 // y
          positions[i + 2] = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2 // z

          scales[j] = 1

          i += 3
          j++
        }
      }

      const geometry = new THREE.BufferGeometry()
      geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
      geometry.setAttribute('scale', new THREE.BufferAttribute(scales, 1))

      // const pMaterial = new THREE.ParticleBasicMaterial({
      const pMaterial = new THREE.PointsMaterial({
        color: 0xffffff,
        size: 20,
        map: THREE.ImageUtils.loadTexture(ShapeUrl),
        blending: THREE.AdditiveBlending,
        transparent: true,
      })

      //

      particles = new THREE.Points(geometry, pMaterial)
      scene.add(particles)

      //

      renderer = new THREE.WebGLRenderer({ antialias: true })
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(window.innerWidth, window.innerHeight)
      mountRef.current.appendChild(renderer.domElement)
      // container.appendChild(renderer.domElement)

      mountRef.current.style.touchAction = 'none'
      mountRef.current.addEventListener('pointermove', onPointerMove)

      //

      window.addEventListener('resize', onWindowResize)
    }

    function onWindowResize() {
      windowHalfX = window.innerWidth / 2
      windowHalfY = window.innerHeight / 2

      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()

      renderer.setSize(window.innerWidth, window.innerHeight)
    }

    //

    function onPointerMove(event) {
      if (event.isPrimary === false) return

      mouseX = event.clientX - windowHalfX
      mouseY = event.clientY - windowHalfY * 1.5
    }

    //

    function animate() {
      requestAnimationFrame(animate)
      render()
    }

    function render() {
      camera.position.x += (mouseX - camera.position.x) * 0.05
      camera.position.y += (-mouseY - camera.position.y) * 0.05
      camera.lookAt(scene.position)

      const positions = particles.geometry.attributes.position.array
      const scales = particles.geometry.attributes.scale.array

      let i = 0,
        j = 0

      for (let ix = 0; ix < AMOUNTX; ix++) {
        for (let iy = 0; iy < AMOUNTY; iy++) {
          positions[i + 1] =
            Math.sin((ix + count) * 0.3) * 50 +
            Math.sin((iy + count) * 0.5) * 50

          scales[j] =
            (Math.sin((ix + count) * 0.3) + 1) * 2 +
            (Math.sin((iy + count) * 0.5) + 1) * 2

          i += 3
          j++
        }
      }

      particles.geometry.attributes.position.needsUpdate = true
      particles.geometry.attributes.scale.needsUpdate = true

      renderer.render(scene, camera)

      count += 0.05
    }

    init()
    animate()

    // return () => mountRef.current.removeChild(renderer.domElement)
  }, [])

  return <div ref={mountRef}></div>
}

export default Component
