import React from 'react'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ content }) => {
  const { items } = content
  return (
    <>
      <Inview className={`${styles.border} fade-in up`} />
      <div className={`grid-12 ${styles.grid}`}>
        {items.map((item, i) => (
          <Item content={item} key={i} />
        ))}
      </div>
    </>
  )
}

export default Component

const Item = ({ content }) => {
  const { pretitle, title, subtitle, excerpt, image, link } = content
  return (
    <LinkWrap className={`${styles.item} zoom-parent`} to={link}>
      <Inview className='fade-in up'>
        <div className={`${styles.image} zoom`}>
          <ImageWrap image={image} />
        </div>
        <div className={styles.text}>
          {pretitle && <h6>{pretitle}</h6>}
          <h4>{title}</h4>
          {subtitle && <h6>{subtitle}</h6>}
          {excerpt && <MarkdownWrap className='p-smaller' body={excerpt} />}
        </div>
      </Inview>
    </LinkWrap>
  )
}
