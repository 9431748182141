import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Grid2Left from 'components/Grid2Left'
import TitleLine from 'components/TitleLine'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale, pageContext }) => {
  const productCats = data?.productCats?.nodes
  const productCatsLocale = productCats?.map((item) => item.frontmatter[locale])

  const title = {
    en: 'Applications',
    zh: '应用行业',
  }
  const grid = {
    items: productCatsLocale.map((item) => {
      return {
        title: item.title,
        subtitle: item.subtitle,
        image: item.image,
        link: `/products/${item.slug}`,
      }
    }),
  }

  return (
    <div className='block pad-v-standard'>
      <div className='container grid-24'>
        <div className={styles.inner}>
          <TitleLine content={{ title: title[locale] }} />
          <div>
            <Grid2Left content={grid} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Data({ content, locale, pageContext }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          productCats: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "productCat" } } }
            }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  title
                  subtitle
                  slug
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  order
                  title
                  subtitle
                  slug
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Block
          content={content}
          data={data}
          locale={locale}
          pageContext={pageContext}
        />
      )}
    />
  )
}
