import React from 'react'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ content }) => {
  const { items } = content
  return (
    <div className={`grid-24 ${styles.grid}`}>
      {items.map((item, i) => (
        <Item content={item} key={i} />
      ))}
    </div>
  )
}

export default Component

const Item = ({ content }) => {
  const { pretitle, title, subtitle, body } = content
  return (
    <div className={`${styles.item}`}>
      <Inview className='fade-in up'>
        <div className={styles.text}>
          {pretitle && <h5>{pretitle}</h5>}
          {title && <h3>{title}</h3>}
          {subtitle && <h6>{subtitle}</h6>}
          {body && (
            <div className={styles.body}>
              <MarkdownWrap body={body} />
            </div>
          )}
        </div>
      </Inview>
    </div>
  )
}
