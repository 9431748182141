import React from 'react'
import BannerHome from 'blocks/BannerHome/template'
import BannerImageText from 'blocks/BannerImageText/template'
import BannerTextOnly from 'blocks/BannerTextOnly/template'
import ContactForm from 'blocks/ContactForm/template'
import ImageFullText from '/src/blocks/ImageFullText/template'
import JsAnimation from '/src/blocks/JsAnimation/template'
import TextImage from 'blocks/TextImage/template'
import TextImageSquare from 'blocks/TextImageSquare/template'
import TextImageCentered from 'blocks/TextImageCentered/template'
import TextOnly from 'blocks/TextOnly/template'
import TextOnlyCentered from 'blocks/TextOnlyCentered/template'
import TextTable from 'blocks/TextTable/template'
import Timeline from 'blocks/Timeline/template'
import TitleBodyHorizontal from '/src/blocks/TitleBodyHorizontal/template'
import TitleBodyHorizontalSmaller from '/src/blocks/TitleBodyHorizontalSmaller/template'
import TitleBodyHorizontalSmallerNews from '/src/blocks/TitleBodyHorizontalSmallerNews/template'
import AwardsTable from '/src/blocks/_dynamic/AwardsTable/template'
import ProductCatsSlideshow from '/src/blocks/_dynamic/ProductCatsSlideshow/template'
import InvestorBanner from '/src/blocks/_dynamic/InvestorBanner/template'
import InvestorTable from '/src/blocks/_dynamic/InvestorTable/template'
import LocationsAddresses from '/src/blocks/_dynamic/LocationsAddresses/template'
import LocationsList from '/src/blocks/_dynamic/LocationsList/template'
import MediaLatest from '/src/blocks/_dynamic/MediaLatest/template'
import NewsBanner from '/src/blocks/_dynamic/NewsBanner/template'
import NewsGrid from '/src/blocks/_dynamic/NewsGrid/template'
import ProductCatsGrid from '/src/blocks/_dynamic/ProductCatsGrid/template'
import TechnologiesSlideshow from '/src/blocks/_dynamic/TechnologiesSlideshow/template'
import Footer from 'components/Footer'
import DarkModeToggle from 'blocks/DarkModeToggle/template'
import ShadeModeToggle from 'blocks/ShadeModeToggle/template'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import * as styles from './styles.module.scss'

const components = {
  bannerHome: BannerHome,
  bannerImageText: BannerImageText,
  bannerTextOnly: BannerTextOnly,
  contactForm: ContactForm,
  imageFullText: ImageFullText,
  jsAnimation: JsAnimation,
  textImage: TextImage,
  textImageSquare: TextImageSquare,
  textImageCentered: TextImageCentered,
  textOnly: TextOnly,
  textOnlyCentered: TextOnlyCentered,
  textTable: TextTable,
  timeline: Timeline,
  titleBodyHorizontal: TitleBodyHorizontal,
  titleBodyHorizontalSmaller: TitleBodyHorizontalSmaller,
  titleBodyHorizontalSmallerNews: TitleBodyHorizontalSmallerNews,
  awardsTable: AwardsTable,
  productCatsSlideshow: ProductCatsSlideshow,
  investorBanner: InvestorBanner,
  investorTable: InvestorTable,
  locationsAddresses: LocationsAddresses,
  locationsList: LocationsList,
  mediaLatest: MediaLatest,
  newsBanner: NewsBanner,
  newsGrid: NewsGrid,
  productCatsGrid: ProductCatsGrid,
  technologiesSlideshow: TechnologiesSlideshow,
  darkModeToggle: DarkModeToggle,
  shadeModeToggle: ShadeModeToggle,
}

const createThemeBlocks = (blocks) => {
  let themeBlocks = []
  let currentInnerIndex = 0
  let currentTheme = 'light'
  blocks?.forEach((block, i) => {
    if (block.type === 'darkModeToggle' && currentTheme !== 'dark') {
      // start a new dark group
      if (i !== 0) {
        currentInnerIndex++
      }
      currentTheme = 'dark'
      themeBlocks[currentInnerIndex] = {
        theme: currentTheme,
        blocks: [],
      }
    } else if (block.type === 'darkModeToggle' && currentTheme === 'dark') {
      // start a new light group
      currentInnerIndex++
      currentTheme = 'light'
      themeBlocks[currentInnerIndex] = {
        theme: currentTheme,
        blocks: [],
      }
    }
    if (block.type === 'shadeModeToggle' && currentTheme !== 'shade') {
      // start a new shade group
      if (i !== 0) {
        currentInnerIndex++
      }
      currentTheme = 'shade'
      themeBlocks[currentInnerIndex] = {
        theme: currentTheme,
        blocks: [],
      }
    } else if (block.type === 'shadeModeToggle' && currentTheme === 'shade') {
      // start a new light group
      currentInnerIndex++
      currentTheme = 'light'
      themeBlocks[currentInnerIndex] = {
        theme: currentTheme,
        blocks: [],
      }
    } else if (i === 0) {
      // it's just the first block, with no toggle
      themeBlocks[currentInnerIndex] = {
        theme: currentTheme,
        blocks: [],
      }
      themeBlocks[currentInnerIndex].blocks.push(block)
    } else {
      // just push the block into the current group
      themeBlocks[currentInnerIndex].blocks.push(block)
    }
  })
  return themeBlocks
}

const ContentType = ({ pageId, content, locale, layoutProps, pageContext }) => {
  if (!locale) locale = 'en'
  let { blocks } = content

  const themeBlocks = createThemeBlocks(blocks)

  return (
    <div className={`${styles.page} page`}>
      {themeBlocks.map((item, i) => (
        <ThemeGroup
          content={item}
          layoutProps={layoutProps}
          pageContext={pageContext}
          locale={locale}
          key={i}
        />
      ))}
      <Footer locale={locale} />
    </div>
  )
}

export default ContentType

const ThemeGroup = ({ content, layoutProps, pageContext, locale }) => {
  const { theme, blocks } = content

  return (
    <div className={`block theme bg-${theme}`}>
      <HeaderVersionWrap layoutProps={layoutProps} isDark={theme === 'dark'}>
        {blocks.map((block, i) => {
          if (
            components[block.type] &&
            block.type !== 'darkModeToggle' &&
            block.type !== 'shadeModeToggle'
          ) {
            return React.createElement(components[block.type], {
              content: block,
              locale,
              key: i,
              layoutProps: layoutProps,
              pageContext: pageContext,
              theme: theme,
            })
          }
        })}
      </HeaderVersionWrap>
    </div>
  )
}
