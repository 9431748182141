import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Grid2 from 'components/Grid2'
import PaginationNumbers from 'components/PaginationNumbers'
import { formatDate } from 'js/utils'

const Block = ({ content, data, locale, pageContext }) => {
  const newsPosts = data?.newsPosts?.nodes
  const newsPostsLocale = newsPosts?.map((item) => item.frontmatter[locale])
  const {
    skip,
    limit,
    previousPagePath,
    nextPagePath,
    numberOfPages,
    humanPageNumber,
  } = pageContext
  const newsPostsLocalePaginated = newsPostsLocale.slice(skip, skip + limit)

  const grid = {
    items: newsPostsLocalePaginated.map((item) => {
      return {
        pretitle: formatDate(item.date, locale),
        title: item.title,
        excerpt: item.excerpt,
        image: item.imageSet.image,
        link: `/news/${item.slug}`,
      }
    }),
  }

  let pageNumbers = []
  for (let i = 1; i <= numberOfPages; i++) {
    let to = `/news/${i}`
    if (i === 1) to = `/news`
    if (i === humanPageNumber) to = ''
    pageNumbers.push({
      number: i,
      to,
    })
  }
  const paginationNumbers = {
    previousPagePath,
    nextPagePath,
    pageNumbers,
  }

  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        <Grid2 content={grid} />
        <PaginationNumbers content={paginationNumbers} locale={locale} />
      </div>
    </div>
  )
}

export default function Data({ content, locale, pageContext }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          newsPosts: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "newsPost" } } } }
            sort: { fields: frontmatter___en___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                en {
                  date
                  title
                  slug
                  excerpt
                  imageSet {
                    image {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    imageMobile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                zh {
                  date
                  title
                  slug
                  excerpt
                  imageSet {
                    image {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    imageMobile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Block
          content={content}
          data={data}
          locale={locale}
          pageContext={pageContext}
        />
      )}
    />
  )
}
