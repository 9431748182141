import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ content }) => {
  const { items } = content
  return (
    <Inview className={`table ${styles.table} fade-in up`}>
      {items.map((item, i) => (
        <Row content={item} key={i} />
      ))}
    </Inview>
  )
}

export default Component

const Row = ({ content }) => {
  const { date, title, image } = content
  let year = date.slice(0, 4)
  const hasImageClass = image ? styles.hasImage : ''
  return (
    <div className={`tr ${styles.row} ${hasImageClass}`}>
      <div className={`td ${styles.year}`}>{year}</div>
      <div className={`td`}>{title}</div>
      {image && (
        <div className={styles.image}>
          <ImageWrap image={image} />
        </div>
      )}
    </div>
  )
}
