import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import TechnologiesSlideshow from 'components/Slideshows/TechnologiesSlideshow'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale, theme }) => {
  const { title, buttonLink } = content

  const technologies = data?.technologies?.nodes
  const technologiesLocale = technologies?.map(
    (item) => item.frontmatter[locale]
  )

  const slideshow = {
    items: technologiesLocale,
  }

  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          <h2>{title}</h2>
        </Inview>
      </div>
      <div className={styles.slideshowWrap}>
        <TechnologiesSlideshow content={slideshow} />
      </div>
      <div className='container'>
        {buttonLink && (
          <Inview className={`button-wrap centered fade-in up`}>
            <ButtonLink content={buttonLink} theme={theme} />
          </Inview>
        )}
      </div>
    </div>
  )
}

export default function Data({ content, locale, theme }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          technologies: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "technology" } } }
            }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  pretitle
                  title
                  subtitle
                  body
                }
                zh {
                  order
                  slug
                  pretitle
                  title
                  subtitle
                  body
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Block content={content} data={data} locale={locale} theme={theme} />
      )}
    />
  )
}
