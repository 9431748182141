// extracted by mini-css-extract-plugin
export var containerInner = "styles-module--container-inner--Z00-6";
export var inner = "styles-module--inner--gsw9f";
export var isActive = "styles-module--is-active--lO7NN";
export var isNear = "styles-module--is-near--w5WfV";
export var isPlaceholder = "styles-module--is-placeholder--J+v9+";
export var orbit = "styles-module--orbit--TTKmc";
export var slideImage = "styles-module--slide-image--iueCf";
export var slideImageInner = "styles-module--slide-image-inner--lFR9Q";
export var slideText = "styles-module--slide-text--bXKcH";
export var swiperComponent = "styles-module--swiper-component--LBWQ4";
export var swiperImages = "styles-module--swiper-images--N+bVP";
export var swiperNav = "styles-module--swiper-nav--nrMx9";
export var swiperText = "styles-module--swiper-text--yLjn3";
export var text = "styles-module--text--aIwY+";