import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import LinkWrap from 'components/LinkWrap'
import LineLink from 'components/LineLink'
import Inview from 'components/Inview'
import { formatDate } from 'js/utils'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const newsPosts = data?.newsPosts?.nodes
  const newsPostsLocale = newsPosts?.map((item) => item.frontmatter[locale])

  const strings = {
    en: {
      news: 'News & Events',
      investor: 'Investor Relations',
    },
    zh: {
      news: '新闻资讯',
      investor: '投资者关系',
    },
  }
  const newsPostItems = newsPostsLocale.map((item) => {
    return {
      pretitle: strings[locale].news,
      title: item.title,
      date: formatDate(item.date, locale),
      link: `/news/${item.slug}`,
    }
  })

  const investorPosts = data?.investorPosts?.nodes
  const investorPostsLocale = investorPosts?.map(
    (item) => item.frontmatter[locale]
  )
  const investorPostItems = investorPostsLocale.map((item) => {
    return {
      pretitle: strings[locale].investor,
      title: item.title,
      date: formatDate(item.date, locale),
      link: `/investors/${item.slug}`,
      file: item.file,
    }
  })
  const allItems = [
    newsPostItems[0],
    newsPostItems[1],
    investorPostItems[0],
    investorPostItems[1],
  ]

  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        <div className={`grid-24 ${styles.grid}`}>
          {allItems.map((item, i) => {
            if (item) return <Item content={item} key={i} />
          })}
        </div>
      </div>
    </div>
  )
}

const Item = ({ content }) => {
  const { file, link } = content
  if (file) {
    return (
      <a className={styles.item} href={file.publicURL} target='_blank'>
        <ItemInner content={content} />
      </a>
    )
  } else {
    return (
      <LinkWrap className={styles.item} to={link}>
        <ItemInner content={content} />
      </LinkWrap>
    )
  }
}
const ItemInner = ({ content }) => {
  const { pretitle, title, date } = content
  return (
    <Inview className='fade-in up'>
      <div>
        <h6>{pretitle}</h6>
        <h4 className={styles.title}>{title}</h4>
      </div>
      <h6 className={styles.date}>{date}</h6>
    </Inview>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          newsPosts: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "newsPost" } } } }
            sort: { fields: frontmatter___en___date, order: DESC }
            limit: 2
          ) {
            nodes {
              frontmatter {
                en {
                  date
                  title
                  slug
                }
                zh {
                  date
                  title
                  slug
                }
              }
            }
          }
          investorPosts: allMarkdownRemark(
            filter: {
              frontmatter: {
                en: {
                  templateKey: { eq: "investorPost" }
                  slug: { ne: "placeholder" }
                }
              }
            }
            sort: { fields: frontmatter___en___date, order: DESC }
            limit: 2
          ) {
            nodes {
              frontmatter {
                en {
                  date
                  title
                  slug
                  file {
                    publicURL
                  }
                }
                zh {
                  date
                  title
                  slug
                  file {
                    publicURL
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
// sort: { fields: frontmatter___en___order, order: ASC }
