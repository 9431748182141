import React from 'react'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import ArrowRightSvg from 'assets/svg/ui/arrow-right.svg'
import * as styles from './styles.module.scss'

const Component = ({ content }) => {
  const { items } = content
  return (
    <>
      <Inview className='fade-in up' />
      <div className={`grid-12 ${styles.grid}`}>
        {items.map((item, i) => (
          <Item content={item} key={i} />
        ))}
      </div>
    </>
  )
}

export default Component

const Item = ({ content }) => {
  const { title, subtitle, image, link } = content
  return (
    <LinkWrap className={`${styles.item} zoom-parent`} to={link}>
      <Inview className='fade-in up'>
        <div className={`${styles.image} zoom`}>
          <ImageWrap image={image} />
        </div>
        <div className={styles.text}>
          <h3>{title}</h3>
          <h5>
            {subtitle} <ArrowRightSvg />
          </h5>
        </div>
      </Inview>
    </LinkWrap>
  )
}
