import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import TableHover from 'components/TableHover'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const { title } = content

  const awards = data?.awards?.nodes
  const awardsLocale = awards?.map((item) => item.frontmatter[locale])

  const table = {
    items: awardsLocale.map((item) => {
      return {
        date: item.date,
        title: item.title,
        image: item.image,
      }
    }),
  }

  return (
    <div className='block pad-v-standard'>
      <div className='container grid-12'>
        <div className={styles.tableWrap}>
          <TableHover content={table} />
        </div>
      </div>
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          awards: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "award" } } } }
            sort: { fields: frontmatter___en___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                en {
                  date
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  date
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
