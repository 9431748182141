import React from 'react'
import ButtonLink from 'components/ButtonLink'
import ImageSet from 'components/ImageSet'
import ParallaxWrap from 'components/ParallaxWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { imageSet, pretitle, title, buttonLink, isBoxLeft = false } = content
  const boxPositionClass = isBoxLeft ? '' : styles.isRight
  return (
    <Inview className={`block ${styles.component}  ${boxPositionClass}`}>
      <div className={`${styles.image} fade-in`}>
        <ParallaxWrap>
          <ImageSet content={imageSet} />
        </ParallaxWrap>
      </div>
      <div className={`${styles.text} fade-in up stagger-500`}>
        <div className={`container ${styles.inner}`}>
          <div className={styles.box}>
            {pretitle && <h5>{pretitle}</h5>}
            {title && <h2>{title}</h2>}
            <div className={styles.buttonWrap}>
              {buttonLink && <ButtonLink content={buttonLink} />}
            </div>
          </div>
        </div>
      </div>
    </Inview>
  )
}

export default Block
