import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'
import LineLink from 'components/LineLink'
import * as styles from './styles.module.scss'
import { formatDate } from 'js/utils'
import LeavesSvg from 'assets/svg/icons/leaves.svg'

const Block = ({ content, data, locale }) => {
  const { title, body } = content
  const posts = data?.posts?.nodes
  const postsLocale = posts?.map((item) => item.frontmatter[locale])
  const thisPost = postsLocale.find((post) => post.slug === content.newsPost)

  const strings = {
    en: {
      news: 'News & Events',
    },
    zh: {
      news: '新闻资讯',
    },
  }
  const postPretitle = `${formatDate(thisPost.date, locale)} &nbsp;|&nbsp; 
  ${strings[locale].news}`
  const postLink = `/news/${thisPost.slug}`

  return (
    <div className='block pad-v-standard'>
      <Inview className='container fade-in up grid-24'>
        <div className={styles.title}>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className={styles.body}>
          <MarkdownWrap className='p-smaller' body={body} />
          <div className={styles.post}>
            <div className={styles.inner}>
              <span className={styles.icon}>
                <LeavesSvg />
              </span>
              <h5
                className='smaller'
                dangerouslySetInnerHTML={{ __html: postPretitle }}
              />
              <h4 className='smaller'>
                <LineLink content={{ to: postLink, text: thisPost.title }} />
              </h4>
            </div>
          </div>
        </div>
      </Inview>
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          posts: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "newsPost" } } } }
            sort: { fields: frontmatter___en___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                en {
                  date
                  slug
                  title
                }
                zh {
                  date
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
