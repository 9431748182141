import React from 'react'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
import { formatDate } from 'js/utils'
import PdfSvg from 'assets/svg/icons/pdf.svg'

const Component = ({ content, locale, theme }) => {
  const themeClass = theme === 'dark' ? styles.themeDark : ''
  const { items } = content
  return (
    <Inview className={`table ${styles.table} ${themeClass} fade-in up`}>
      {items.map((item, i) => (
        <Row content={item} key={i} locale={locale} theme={theme} />
      ))}
    </Inview>
  )
}

export default Component

const Row = ({ content, locale, theme }) => {
  const { date, title, link, file } = content
  const dateFormatted = formatDate(date, locale)
  const buttonText = {
    en: 'More',
    zh: '更多',
  }
  return (
    <div className='tr'>
      <div className={`td ${styles.td} ${styles.tdDate}`}>{dateFormatted}</div>
      <div className={`td ${styles.td}`}>{title}</div>
      <div className={`td ${styles.td}`}>
        {file && (
          <a className={styles.iconWrap} href={file.publicURL} target='_blank'>
            <PdfSvg />
          </a>
        )}
        {!file && (
          <div className={styles.buttonWrap}>
            <ButtonLink
              content={{ to: link, text: buttonText[locale] }}
              theme={theme}
            />
          </div>
        )}
      </div>
    </div>
  )
}
