import React from 'react'
import MarkdownWrap from 'components/MarkdownWrap'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { title, subtitle, items } = content
  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        {(title || subtitle) && (
          <Inview className={`${styles.title} fade-in up`}>
            {title && <h2>{title}</h2>}
            {subtitle && <h5>{subtitle}</h5>}
          </Inview>
        )}
        <div className={styles.items}>
          {items.map((item, i) => (
            <Item content={item} key={i} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Block

const Item = ({ content }) => {
  const { title, subtitle, body, image } = content
  return (
    <Inview className={`grid-24 ${styles.item}`}>
      <div className={`${styles.image} fade-in`}>
        <ImageWrap image={image} />
      </div>
      <div className={`${styles.lineMid} fade-in`} />
      <div className={`${styles.lineSide} fade-in`} />
      <div className={`${styles.text} fade-in`}>
        {title && <h2>{title}</h2>}
        {subtitle && <h3 className='smaller'>{subtitle}</h3>}
        {body && <MarkdownWrap className='p-smaller' body={body} />}
      </div>
    </Inview>
  )
}
