import React from 'react'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, theme }) => {
  const { pretitle, title, body, image, isImageFirst, buttonLink } =
    content || {}
  const imageFirstClass = isImageFirst ? styles.imageFirst : ''
  return (
    <Inview className='pad-v-standard fade-in up'>
      <div className={`container grid-24 ${styles.grid} ${imageFirstClass}`}>
        <div className={styles.text}>
          {pretitle && <h5>{pretitle}</h5>}
          <h2>{title}</h2>
          <div className='body'>
            <MarkdownWrap body={body} />
          </div>
          {buttonLink && (
            <Inview className={`button-wrap fade-in up`}>
              <ButtonLink content={buttonLink} theme={theme} />
            </Inview>
          )}
        </div>
        <div className={styles.image}>
          <ImageWrap image={image} />
        </div>
      </div>
    </Inview>
  )
}

export default Block
