import React from 'react'
import Inview from 'components/Inview'
import ContactForm from 'components/Forms/Contact'
import * as styles from './styles.module.scss'

const Block = ({ content, locale, theme }) => {
  const { title } = content
  return (
    <Inview className='block pad-v-standard fade-in up'>
      <div className='container'>
        {title && (
          <div className={styles.title}>
            <h2>{title}</h2>
          </div>
        )}
        <div className={styles.formWrap}>
          <ContactForm locale={locale} theme={theme} />
        </div>
      </div>
    </Inview>
  )
}

export default Block
