import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Grid2Text from 'components/Grid2Text'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const { title } = content

  const locations = data?.locations?.nodes
  const locationsLocale = locations?.map((item) => item.frontmatter[locale])

  const grid = {
    items: locationsLocale.map((item) => {
      let { title, address, phone, email } = item
      if (phone) {
        address += `
        ${phone}`
      }
      if (email) {
        address += `
        ${email}`
      }
      return {
        pretitle: title,
        body: address,
      }
    }),
  }

  return (
    <div className='block pad-v-standard first-add-v-pad'>
      <div className='container'>
        <Inview className='fade-in up'>
          <div className={styles.title}>
            <h2>{title}</h2>
          </div>
          <div className={styles.gridWrap}>
            <Grid2Text content={grid} />
          </div>
        </Inview>
      </div>
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          locations: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "location" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  address
                  phone
                  email
                }
                zh {
                  slug
                  title
                  address
                  phone
                  email
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
