import React, { useEffect, useState } from 'react'
import ArrowLong from 'components/ArrowLong'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import OrbitSvg from 'assets/svg/orbit.svg'
import * as styles from './styles.module.scss'

SwiperCore.use([Autoplay, Navigation, Pagination])

const Slideshow = ({ content }) => {
  const { items } = content
  const [swiper, setSwiper] = useState(null)
  const [textIndex, setTextIndex] = useState(0)
  const handleSlideChange = () => {
    if (swiper) {
      setTextIndex(swiper.realIndex)
    }
  }

  return (
    <Inview className={`${styles.swiperComponent} fade-in up`}>
      <div className=''>
        <div className={styles.containerInner}>
          <SwiperImages
            items={items}
            setSwiper={setSwiper}
            handleSlideChange={handleSlideChange}
          />
          <div className='container'>
            <SwiperText items={items} textIndex={textIndex} />
          </div>
        </div>
      </div>
    </Inview>
  )
}

const SwiperImages = ({ items, setSwiper, handleSlideChange }) => {
  return (
    <div className={styles.swiperImages}>
      <div className={styles.orbit}>
        <OrbitSvg />
      </div>
      <Swiper
        onSwiper={setSwiper}
        onSlideChange={handleSlideChange}
        navigation={{
          prevEl: '.swiper-prev',
          nextEl: '.swiper-next',
        }}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }}
        loop={true}
        loopAdditionalSlides={items.length - 1}
        centeredSlides={true}
        speed={600}
        autoplay={{
          delay: 5000,
        }}
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: '1%',
          },
          768: {
            slidesPerView: 3,
            spaceBetween: '10%',
          },
          1025: {
            slidesPerView: 3,
            spaceBetween: '10%',
          },
        }}
      >
        {items?.map((item, i) => (
          <SwiperSlide key={i}>
            {({ isActive, isPrev, isNext }) => (
              <SlideImage
                content={item}
                isActive={isActive}
                isPrev={isPrev}
                isNext={isNext}
              />
            )}
          </SwiperSlide>
        ))}
        <div className={styles.swiperNav}>
          <div className='container'>
            <div className={styles.inner}>
              <div className='swiper-prev'>
                <ArrowLong direction='left' color='white' />
              </div>
              <div className='swiper-next'>
                <ArrowLong color='white' />
              </div>
            </div>
          </div>
        </div>
      </Swiper>
    </div>
  )
}

const SwiperText = ({ items, textIndex }) => {
  let maxBodyLength = 0
  let placeholderSlide
  items.forEach((item) => {
    if (item.body?.length > maxBodyLength) {
      maxBodyLength = item.body?.length
      placeholderSlide = item
    }
  })

  return (
    <div className={styles.swiperText}>
      {items?.map((item, i) => {
        const isActive = i === textIndex
        return <SlideText content={item} isActive={isActive} key={i} />
      })}
      <SlideText content={placeholderSlide} isPlaceholder={true} />
      <div className={`swiper-pagination`} />
    </div>
  )
}

const SlideImage = ({ content, isActive, isPrev, isNext }) => {
  const { pretitle, title } = content
  const isActiveClass = isActive ? styles.isActive : ''
  const isNearClass = isPrev || isNext ? styles.isNear : ''
  return (
    <div className={`${styles.slideImage}`}>
      <div
        className={`${styles.slideImageInner}  ${isActiveClass} ${isNearClass}`}
      >
        <div className={styles.text}>
          <h5>{pretitle}</h5>
          <h3>{title}</h3>
        </div>
      </div>
    </div>
  )
}

const SlideText = ({ content, isActive, isPlaceholder }) => {
  const { subtitle, body } = content
  const isActiveClass = isActive ? styles.isActive : ''
  const isPlaceholderClass = isPlaceholder ? styles.isPlaceholder : ''
  return (
    <div
      className={`${styles.slideText} ${isActiveClass} ${isPlaceholderClass}`}
    >
      <h4>{subtitle}</h4>
      <MarkdownWrap body={body} />
    </div>
  )
}

export default Slideshow
