import React, { useState } from 'react'
import ButtonLink from 'components/ButtonLink'
import { Field, Form } from 'react-final-form'
import axios from 'axios'
import LoaderSvg from 'assets/svg/ui/loader.svg'
import * as styles from './styles.module.scss'

const ContactForm = ({ locale, theme }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isMessageShowing, setIsMessageShowing] = useState(false)

  const onSubmit = (postData) => {
    setIsMessageShowing(false)
    setIsLoading(true)
    const url = process.env.GATSBY_EMAIL_URL + '/contact-form/email.php'
    axios({
      method: 'post',
      url,
      data: {
        postData,
        isEmailLive: eval(process.env.GATSBY_IS_EMAIL_LIVE),
      },
    }).then((response) => {
      setIsLoading(false)
      setIsMessageShowing(true)
      setTimeout(() => {
        setIsMessageShowing(false)
      }, 3000)
    })
  }

  const localeStrings = {
    en: {
      name: 'Name',
      phone: 'Phone',
      email: 'Email',
      company: 'Company',
      message: 'Message',
      submit: 'Submit',
      confirmMessage: 'Thanks for your message.',
    },
    zh: {
      name: '姓名',
      phone: '联系电话',
      email: '邮箱',
      company: '公司名称',
      message: '正文',
      submit: '提交',
      confirmMessage: '感谢您的留言。',
    },
  }
  const strings = localeStrings[locale]

  const isMessageShowingClass = isMessageShowing ? styles.show : ''
  const isLoadingClass = isLoading ? styles.isLoading : ''

  return (
    <div className={styles.formContact}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            // noValidate
            onSubmit={async (event) => {
              await handleSubmit(event)
              form.reset()
            }}
          >
            <div className={styles.fieldRowDouble}>
              <Field
                name='name'
                placeholder={strings.name}
                component='input'
                type='text'
                required
              />
              <Field
                name='company'
                placeholder={strings.company}
                component='input'
                type='text'
                required
              />
            </div>
            <div className={styles.fieldRowDouble}>
              <Field
                name='email'
                placeholder={strings.email}
                component='input'
                type='email'
                required
              />
              <Field
                name='phone'
                placeholder={strings.phone}
                component='input'
                type='text'
                required
              />
            </div>
            <div className={styles.fieldRowDouble}>
              <Field
                name='message'
                component='textarea'
                placeholder={strings.message}
                required
              />
            </div>
            <div className={styles.submitWrap}>
              <div className={`${styles.loaderWrap} ${isLoadingClass}`}>
                <ButtonLink
                  content={{
                    text: strings.submit,
                    linkType: 'submit',
                    isDisabled: isLoading,
                  }}
                  theme={theme}
                />
                <div className={styles.formLoader}>
                  <LoaderSvg />
                </div>
              </div>
            </div>
            <p className={`${styles.confirmMessage} ${isMessageShowingClass}`}>
              {strings.confirmMessage}
            </p>
          </form>
        )}
      />
    </div>
  )
}

export default ContactForm
