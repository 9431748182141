import React, { useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
// import * as styles from './styles.module.scss'

const Component = ({ children }) => {
  // const [isInView, setIsInView] = useState(false)
  // const handleChange = (inView, entry) => {
  //   setIsInView(inView)
  // }
  // const inViewClass = isInView ? styles.markInView : ''

  const parallax = React.useRef(null)

  useEffect(() => {
    const scrollHandler = () => {
      const element = parallax.current
      if (element) {
        const parent = element.closest('.block')
        if (parent) {
          const parentCenter = parent.offsetTop + parent.offsetHeight / 2
          const windowCenter = window.scrollY + window.innerHeight / 2
          const scrollPos = windowCenter - parentCenter
          const yVal = scrollPos * 0.3
          element.style.transform = `translate3d(0, ${yVal}px, 0)`
        }
      }
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])

  return (
    <div ref={parallax}>
      {/* <InView onChange={handleChange} threshold={0.5} triggerOnce={true}> */}
      {children}
      {/* </InView> */}
    </div>
  )
}

export default Component
