import React from 'react'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { title, body, table } = content
  return (
    <div className='block pad-v-standard'>
      <Inview className='container fade-in up grid-12'>
        <div className={styles.textWrap}>
          <h2>{title}</h2>
          <MarkdownWrap className={styles.body} body={body} />
          <MarkdownWrap className={styles.table} body={table} />
        </div>
      </Inview>
    </div>
  )
}

export default Block
